import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isDesktop } from "./helper.utils";
import { getItem, getItemAsArray, getItemAsObject, setItem } from "./localStorage.utils";
import { toggleSnackbarOpen } from "../app/slices/snacbar";
import { rpcClient } from "../helper/rpcClient";
import { IUnlockAccount } from "../pages/unlockModal/unlockModal";
import { isLoading } from "../app/slices/status";
import { setUserInfo } from "../app/slices/userInfo";
import { setSession } from "../app/slices/session";
import { setCurrentShop, setShops } from "../app/slices/shops";
import { showLockModal } from "../app/slices/accountLock";
import { setRole } from "../app/slices/roles";

interface IUseLogout {
  userInfo: any;
  usersWithPin: any;
  isOnline: boolean;
  setShowshopDeletedWarning?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useLogout = ({
  userInfo,
  usersWithPin,
  isOnline,
  setShowshopDeletedWarning,
}: IUseLogout) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    let authenticatedUsersWithPin = !isDesktop()
      ? getItemAsArray("usersWithPin")
      : usersWithPin?.getAuthenticatedUsersWithPin;

    if (!isOnline && !isDesktop()) {
      dispatch(
        toggleSnackbarOpen({
          message: "You cannot logout while you are offline",
          color: "DANGER",
        })
      );
      return;
    }

    const sessions = getItemAsObject("session");
    const token = sessions?.token || "";

    try {
      dispatch(isLoading(true));
      try {
        await rpcClient.request("logoutUser", {
          token,
          userId: userInfo?.userId,
        });

        authenticatedUsersWithPin = authenticatedUsersWithPin?.filter(
          (user: IUnlockAccount) => user.userId !== userInfo?.userId
        );
        setItem("usersWithPin", authenticatedUsersWithPin);
      } catch (error) {
        console.error("Logout API error:", error);
      }

      if (!authenticatedUsersWithPin || authenticatedUsersWithPin.length <= 0) {
        dispatch(isLoading(false));
        dispatch(setUserInfo({}));
        dispatch(setSession({}));
        dispatch(setShops([]));
        dispatch(setCurrentShop({}));

        const deviceUUID = getItem("DEVICE_UUID");
        localStorage.clear();
        setItem("DEVICE_UUID", deviceUUID);
        navigate("/login");
      } else {
        dispatch(isLoading(false));
        dispatch(showLockModal());
        dispatch(setUserInfo({}));
      }
      dispatch(setRole({}));
    } catch (error) {
      console.error("Error logging out", error);
      localStorage.clear();
      navigate("/login");
    } finally {
      setShowshopDeletedWarning?.(false);
      dispatch(isLoading(false));
    }
  };

  return { handleLogout };
};
