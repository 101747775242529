import { JSONRPCClient } from "json-rpc-2.0";
import { getApiUrl } from "../services/CustomHttp";

// JSONRPCClient needs to know how to send a JSON-RPC request.
// Tell it by passing a function to its constructor. The function must take a JSON-RPC request and send it.

export const rpcClient: JSONRPCClient = new JSONRPCClient(
  async (jsonRPCRequest: any) => {
    try {
      const response = await fetch(getApiUrl("json-rpc"), {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(jsonRPCRequest),
      });

      if (response.status === 200) {
        const jsonRPCResponse = await response.json();
        return rpcClient.receive(jsonRPCResponse);
      } else if (jsonRPCRequest.id !== undefined) {
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error("RPC request failed:", error);
      if (jsonRPCRequest.id !== undefined) {
        throw error;
      }
    }
  }
);
