import { isDesktop } from "../../utils/helper.utils";
import { getItemAsObject } from "../../utils/localStorage.utils";
import { IAnalyticsData, LocalStorageManager, DbStorageManager, EventType } from "./storage";

interface UpdateDefaultPropertiesAttr {
  timeSpent: number;
  flowSource: string;
  timestamp: string
};

class Analytics {
  private trackingServerUrl: string;
  private storageManager: LocalStorageManager | DbStorageManager;
  private defaultProperties: Record<string, any> = {};
  private parsedToken?: string;
  private deviceIds = new Map<string, string>();

  constructor(trackingServerUrl: string, storage: LocalStorageManager | DbStorageManager) {
    this.trackingServerUrl = trackingServerUrl;
    this.storageManager = storage;
    const sessions = getItemAsObject("session");
    const token = sessions.token;
    this.parsedToken = token || "";
  }

  setDefaultProperties(properties: Record<string, string | number | boolean>): void {
    this.defaultProperties = properties;
  }

  updateDefaultProperties({ timeSpent, flowSource, timestamp }: UpdateDefaultPropertiesAttr): void {
    this.defaultProperties = {
      ...this.defaultProperties,
      timeSpentOnPage: timeSpent,
      flowSource,
      timestamp,
    };
  }

  async profile(
    userId: string,
    properties: Record<string, string | number | boolean>
  ): Promise<void> {
    const mergedProperties = { ...properties, ...this.defaultProperties };
    mergedProperties.deviceId = this.getDeviceId(mergedProperties.userId);
    mergedProperties.anonymousId = this.getDeviceId(mergedProperties.userId);

    await this.sendRequest("/profile", { mergedProperties, userId });
  }

  track({ properties, eventName }: IAnalyticsData): void {
    const mergedData = { ...properties, ...this.defaultProperties };

    mergedData.deviceId = this.getDeviceId(mergedData.userId);

    this.sendData({ eventName, properties: mergedData });
  }

  private getDeviceId(userId: string): string {
    let deviceId = this.deviceIds.get(userId);
    if (deviceId) {
      return deviceId;
    }

    deviceId = crypto.randomUUID();
    this.deviceIds.set(userId, deviceId as string);

    return deviceId as string;
  }

  private async sendData(data: IAnalyticsData): Promise<void> {
    await this.resendStoredData();

    const success = await this.sendRequest("/track", data);
    if (!success) {
      this.storageManager.save(data);
    }
  }

  private async resendStoredData(): Promise<void> {
    const storedData = await this.storageManager.load();

    if (storedData.length === 0) {
      console.info("No stored data to resend.");
      return;
    }

    console.info("Resending stored data...");
    const failedData: IAnalyticsData[] = [];

    for (const data of storedData) {
      const parsedData = data;
      const success = await this.sendRequest("/track", parsedData);
      if (!success) {
        failedData.push(data);
      }
    }

    await this.storageManager.clear(this.defaultProperties.shopId);

    if (failedData.length > 0) {
      for (const data of failedData) {
        await this.storageManager.save(data);
      }
    }
  }

  private async sendRequest(endpoint: string, data: object): Promise<boolean> {
    try {
      const response = await fetch(`${this.trackingServerUrl}/analytics${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.parsedToken}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP ${response.status}`);
      }

      console.log(`Data sent successfully to ${endpoint}:`, data);
      return true;
    } catch (error) {
      console.error(`Error sending data to ${endpoint}:`, error);
      return false;
    }
  }
}

const localDbUrl = process.env.REACT_APP_LOCAL_SERVER_API || "";
const localStorageManager = new LocalStorageManager("analyticsUnsentData");
const dbStorageManager = new DbStorageManager(localDbUrl);
const storageManager = isDesktop() ? dbStorageManager : localStorageManager;

const analytics = new Analytics(localDbUrl, storageManager);
export default analytics;
