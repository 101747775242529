import { ReactNode } from "react";
import { FullScreenModalContainer, ModalContainer } from "../../pages/settings/style";
import { Flex } from "../../GlobalStyles/CustomizableGlobal.style";
import useWindowDimensions from "../../utils/useDimension";

type Props = {
  onClose?: (T: boolean) => void;
  children: ReactNode | ReactNode[];
  color?: string;
};
export const ModalWrapper = ({ onClose, children, color }: Props) => {
  return (
    <ModalContainer color={color} onClick={() => onClose?.(false)}>
      <Flex width="100%" justifyContent="center">
        <div
          style={{ width: "fit-content" }}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </Flex>
    </ModalContainer>
  );
};

export const FullScreenModal = ({ onClose, children }: Props) => {
  const { width } = useWindowDimensions();

  return (
    <FullScreenModalContainer onClick={() => onClose?.(false)}>
      <Flex
        onClick={(e) => e.stopPropagation()}
        width={width < 1100 ? "100%" : "calc(100% - 15rem)"}
        justifyContent="flex-end"
        bg="#f4f6f9"
      >
        {children}
      </Flex>
    </FullScreenModalContainer>
  );
};
