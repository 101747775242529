import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

function getWindowDimensions () {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

export default function useWindowDimensions (ref?: React.RefObject<HTMLElement>) {
  const getDimensions = () => {
    if (ref?.current) {
      return { width: ref.current.offsetWidth, height: ref.current.offsetHeight };
    }
    return getWindowDimensions();
  };

  const [dimensions, setDimensions] = useState(getDimensions);

  useEffect(() => {
    function handleResize () {
      setDimensions(getDimensions());
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [ref?.current]);

  return dimensions;
}

export const useResizeObserver = (containerRef: React.MutableRefObject<HTMLDivElement | null>) => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (!containerRef.current) return;

    const handleResize = () => {
      setSize({
        width: containerRef?.current?.offsetWidth || 0,
        height: containerRef?.current?.offsetHeight || 0,
      });
    };

    const resizeObserver = new ResizeObserver(() => handleResize());

    resizeObserver.observe(containerRef.current);
    handleResize();

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return { size };
};

export const useScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);
};
