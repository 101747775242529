import { useEffect, useState } from "react";

const isMobileOrTablet = (width?: number) => {
  const [isMobileOrTabletDevice, setIsMobileOrTabletDevice] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${width ?? 770}px)`); // Adjust the max-width to define the threshold for tablet devices
    const handleResize = () => {
      setIsMobileOrTabletDevice(mediaQuery.matches);
    };

    // Initial check
    handleResize();

    // Listen for resize events
    mediaQuery.addEventListener("change", handleResize);

    // Clean up
    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  return isMobileOrTabletDevice;
};

export default isMobileOrTablet;
