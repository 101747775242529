import { getItemAsObject } from "../../utils/localStorage.utils";

export type EventType =
  | "LogOut"
  | "CreateAccount"
  | "StartCreateAccount"
  | "StartForgotPassword"
  | "ForgotPassword"
  | "StartCreateBusiness"
  | "CompleteCreateBusiness"
  | "OverviewPage"
  | "OverviewPageDateFilter"
  | "StartRecordInflow"
  | "QuickActions";

export interface IAnalyticsData {
  eventName: EventType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties?: Record<string, any>;
}

export class LocalStorageManager {
  private storageKey: string;

  constructor(storageKey: string) {
    this.storageKey = storageKey;
  }

  async save(data: IAnalyticsData): Promise<void> {
    const storedData = await this.load();

    storedData.push(data);
    sessionStorage.setItem(this.storageKey, JSON.stringify(storedData));

    console.info("Data saved to storage:", data);
  }

  async load(): Promise<Array<IAnalyticsData>> {
    const storedData = sessionStorage.getItem(this.storageKey);
    return storedData ? JSON.parse(storedData) : [];
  }

  async clear(): Promise<void> {
    await sessionStorage.removeItem(this.storageKey);

    console.info("Storage cleared.");
  }
}

export class DbStorageManager {
  private serverUrl: string;
  private parsedToken: string;

  constructor(serverUrl: string) {
    this.serverUrl = serverUrl;
    const sessions = getItemAsObject("session");
    const token = sessions.token;
    this.parsedToken = token;
  }

  async save(data: IAnalyticsData): Promise<void> {
    try {
      const response = await fetch(`${this.serverUrl}/analytics/save`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.parsedToken}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Failed to save data: ${response.statusText}`);
      }

      console.info("Data sent to server:", data);
    } catch (error) {
      console.error("Error saving data to server:", error);
    }
  }

  async load(): Promise<IAnalyticsData[]> {
    try {
      const response = await fetch(`${this.serverUrl}/analytics/load`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.parsedToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to load data: ${response.statusText}`);
      }

      const data: { success: true; data: Array<IAnalyticsData> } =
        await response.json();
      console.info("Loaded data from server:", data);
      return data.data;
    } catch (error) {
      console.error("Error loading data from server:", error);
      return [];
    }
  }

  async clear(shopId: string): Promise<void> {
    try {
      const response = await fetch(
        `${this.serverUrl}/analytics/delete/${shopId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.parsedToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to clear data: ${response.statusText}`);
      }

      console.info("Server storage cleared.");
    } catch (error) {
      console.error("Error clearing server storage:", error);
    }
  }
}
