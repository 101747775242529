import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { getCurrentShop } from "../../app/slices/shops";
import { getCurrentUser } from "../../app/slices/userInfo";
import { getItemAsObject } from "../../utils/localStorage.utils";

interface UserProfile {
  deviceUuid: string;
  name: string;
  email: string;
  businessName: string;
  phoneNumber: string;
  $region: string;
  $city: string;
  distinctId: string;
  shopId: string;
  $countryCode: string;
  [key: string]: any;
}

export const useUserProfile = (userId: string) => {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const currentUser = useAppSelector(getCurrentUser);
  const currentShop = useAppSelector(getCurrentShop);
  const localDbUrl = process.env.REACT_APP_LOCAL_SERVER_API || "";

  const fetchIpInfo = useCallback(async () => {
    const sessions = getItemAsObject("session");
    const token = sessions.token;

    try {
      const response = await fetch(`${localDbUrl}/analytics/ip`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error("Error fetching IP information:", error);
      return null;
    }
  }, [localDbUrl]);

  const buildUserProfile = useCallback((ipInfo: any): UserProfile => {
    const { firstName, lastName, email, mobileNumber } = currentUser;
    const { shopName, state, city, shopId } = currentShop;
    const deviceUuid = localStorage.getItem("DEVICE_UUID") || "";

    return {
      deviceUuid,
      name: `${firstName} ${lastName}`,
      email: email || "",
      $email: email || "",
      businessName: shopName || "",
      phoneNumber: mobileNumber || "",
      $region: state || "",
      $city: city || "",
      distinctId: userId || "",
      shopId: shopId || "",
      $country: ipInfo.data.country,
      $countryCode: ipInfo.data.countryCode,
      ipAddress: ipInfo.data.ipAddress,
    };
  }, [currentUser, currentShop]);

  useEffect(() => {
    const loadUserProfile = async () => {
      const ipInfo = await fetchIpInfo();

      if (ipInfo) {
        setUserProfile(buildUserProfile(ipInfo));
      }
    };

    loadUserProfile();
  }, [fetchIpInfo, buildUserProfile]);

  return userProfile;
};
