import React, { ReactNode, Component } from "react";
import { Flex, Span } from "../GlobalStyles/CustomizableGlobal.style";
import { Colors, fontSizes } from "../GlobalStyles/theme";
import { Button } from "../components/button/Button";
import Crash from "../assets/crash.png";
import { FORWARD_TO_EMAIL } from "../schema/shops.schema";
import { client } from "../apollo-client/client";
import ReactDOMServer from "react-dom/server";
import { isDesktop } from "./helper.utils";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  errorMessage?: string | null;
}

interface ErrorPlaceholderProps {
  message?: string | null;
}

const ErrorPlaceHolder: React.FC<ErrorPlaceholderProps> = ({ message }) => {
  const navigateToDashboard = () => {
    window.location.href = "/";
  };

  return (
    <Flex
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      bg={Colors.offWhite}
      justifyContent="center"
    >
      <Flex
        gap="1.5rem"
        padding="4rem"
        width="40rem"
        display="flex"
        alignItems="center"
        flexFlow="column"
        bg="white"
        justifyContent="center"
        smResponsive="width: 20rem; padding: 1rem">

        <img src={Crash} alt="" />
        <Span fontWeight="600" fontSize="2rem" color={Colors.primaryColor}>Whoops!!!</Span>
        <Span fontWeight="600" color={Colors.blackLight}>An unexpected error has occured</Span>

        {process.env.NODE_ENV === "development" && (
          <Flex flexFlow="column" alignItems="center">
            <Span color={Colors.blackLight} fontWeight="600">Details:</Span>
            <Span color={Colors.danger}>{message}</Span>
          </Flex>
        )}

        <Button
          width="80%"
          height="3rem"
          borderRadius="0.4rem"
          fontSize={fontSizes.base}
          color={Colors.white}
          smResponsive="width: 100%"
          borderColor={Colors.secondaryColor}
          backgroundColor={Colors.primaryColor}
          label="Go to Dashboard"
          onClick={navigateToDashboard}
        />

      </Flex>
    </Flex>
  );
};

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, errorMessage: null };
    this.handleError = this.handleError.bind(this);
  }

  private handleError(error?: Error | string | Event) {
    console.error("Render error:", error);

    if (ErrorBoundary.isApiError(error)) return null;

    this.setState({
      hasError: true,
      errorMessage: error instanceof Error ? error.message : String(error),
    });
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorMessage: error.message };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const appVersion = process.env.REACT_APP_VERSION;
    const subject =
      appVersion && appVersion?.length > 0
        ? `${isDesktop() ? "Desktop" : "Web"} App Crash: ${appVersion}`
        : `${isDesktop() ? "Desktop" : "Web"} App Crash`;
    const location = window.location.href;

    const messageContent = (
      <div style={{ display: "block" }}>
        <div style={{ display: "block", marginBottom: "16px" }}>
          <p style={{ margin: "0px", fontSize: "16px", fontWeight: "500" }}>
            Message:
          </p>
          <p style={{ margin: "0px" }}>{error.message}</p>
        </div>
        <div style={{ display: "block", marginBottom: "16px" }}>
          <p style={{ margin: "0px", fontSize: "16px", fontWeight: "500" }}>
            Location:
          </p>
          <p style={{ margin: "0px" }}>{location}</p>
        </div>
        <div style={{ display: "block" }}>
          <p style={{ margin: "0px", fontSize: "16px", fontWeight: "500" }}>
            Stack Trace:
          </p>
          <p style={{ color: "red", margin: "0px" }}>
            {errorInfo?.componentStack ?? errorInfo}
          </p>
        </div>
      </div>
    );

    const message = ReactDOMServer.renderToStaticMarkup(messageContent);

    if (process.env.NODE_ENV === "development") {
      return;
    }

    client
      .mutate({
        mutation: FORWARD_TO_EMAIL,
        variables: {
          subject,
          message,
        },
      })
      .then(() => {
        console.log("Crash email sent");
      })
      .catch((err) => console.error("Failed to send crash email:", err));
  }

  componentDidMount() {
    window.onerror = (message, source, lineno, colno, error) => {
      const errorObject = {
        message,
        source,
        lineno,
        colno,
        error,
      };
    };

    window.addEventListener("unhandledrejection", (event) => {
      this.handleError(event.reason);
    });
  }

  componentWillUnmount() {
    window.onerror = null;
    window.removeEventListener("unhandledrejection", this.handleError);
  }

  static isApiError(error: any): boolean {
    if (!error) return false;

    return (
      (error?.response && error.response.status) ||
      (typeof error === "string" && error.includes("Network Error")) ||
      error?.networkError || error?.graphQLErrors ||
      error.toString().toLowerCase().includes("failed to fetch")
    );
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPlaceHolder message={this.state.errorMessage} />;
    }
    return this.props.children;
  }
}
