import { useMutation } from "@apollo/client";
import { useState } from "react";
import styled from "styled-components";
import { Flex } from "../../GlobalStyles/CustomizableGlobal.style";
import { Colors, fontSizes } from "../../GlobalStyles/theme";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { lock, showLockModal } from "../../app/slices/accountLock";
import { toggleSnackbarOpen } from "../../app/slices/snacbar";
import { COMFIRM_USER_PIN, UPDATE_USER_TOKEN } from "../../schema/auth.schema";
import { getItemAsArray, getItemAsObject, setItem } from "../../utils/localStorage.utils";
import AuthCard from "../auth-card/auth-card";
import { Button } from "../button/Button";
import { InputField } from "../input-field/input";
import { IConfirm, IUnlockAccount } from "../../pages/unlockModal/unlockModal";
import { ModalBox } from "../expenseModal/style";
import { CancelButton } from "../../pages/sales/style";
import Cancel from "../../assets/cancel.svg";
import { ModalWrapper } from "../modal/ModalWrapper";

export const Input = styled.input`
  appearance: none;
  outline: none;
  font-size: ${fontSizes.small};
  background-color: transparent;
  color: black;
  border-style: solid;
  padding-block: 1rem;
  padding-inline: 1rem;
  border-radius: 0.625rem;
  margin-bottom: 0.625rem;
  border: 1px solid black;
  width: 100%;
  height: 3.125rem;
  ::placeholder {
    color: #8196b3;
    opacity: 0.7;
  }

  &:hover {
    cursor: unset;
  }
`;

const ComfirmPinLock = ({
  setShowComfirmPinModal,
}: {
  setShowComfirmPinModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [pin, setPin] = useState("");
  const dispatch = useAppDispatch();
  const { fullName } = useAppSelector((state) => state.user);
  const { session } = useAppSelector((state) => state.session);

  const { blackLight, primaryColor } = Colors;

  const [confirmPin] = useMutation<{ confirmPin: IConfirm }>(COMFIRM_USER_PIN, {
    onQueryUpdated(observableQuery) {
      return observableQuery.refetch();
    },
  });

  const [updateIsPinEnable] = useMutation<{
    updateIsPinEnabled: boolean;
  }>(UPDATE_USER_TOKEN, {
    fetchPolicy: "network-only",
    onError() {
      dispatch(
        toggleSnackbarOpen({
          message: "Failed to update user token",
          color: "DANGER",
        })
      );
    },
  });

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    const currentSession = getItemAsObject("session");
    e.preventDefault();
    try {
      const updateIsPinEnabledResponse = await updateIsPinEnable({
        variables: {
          isPinEnabled: false,
          pin,
        },
      });

      if (updateIsPinEnabledResponse?.data?.updateIsPinEnabled) {
        throw Error("Failed to update isPinEnabled. Please try again later.");
      }

      const res = await confirmPin({
        variables: {
          pin,
          userId: currentSession?.userId,
          token: currentSession?.token,
        },
      });

      if (!res.data?.confirmPin?.success) {
        throw Error("Incorrect Pin.");
      }

      const userId = session?.userId;
      const usersWithPin = getItemAsArray("usersWithPin");
      const hasComfirmedPins = getItemAsArray("hasConfirmedPins");

      if (!usersWithPin.some((userDetails: IUnlockAccount) => userDetails.userId === userId)) {
        usersWithPin.push({
          fullName: fullName,
          userId: session?.userId,
          token: session?.token,
        });
        setItem("usersWithPin", usersWithPin);

        const newComfirmedUser = {
          userId: userId,
          hasConfirmedPin: true,
        };

        hasComfirmedPins.push(newComfirmedUser);

        setItem("hasConfirmedPins", hasComfirmedPins);
      }

      setShowComfirmPinModal(false);
      dispatch(showLockModal());
      dispatch(lock({ lock: true }));
    } catch (error: any) {
      dispatch(
        toggleSnackbarOpen({
          message: "Incorrect Pin. Please try again.",
          color: "DANGER",
        })
      );
    }
  };

  return (
    <ModalWrapper onClose={() => setShowComfirmPinModal(false)}>
      <ModalBox>
        <Flex justifyContent="flex-end">
          <CancelButton
            style={{
              width: "2.5rem",
            }}
            hover
            onClick={() => setShowComfirmPinModal(false)}
          >
            <img src={Cancel} alt="" />
          </CancelButton>
        </Flex>
        <AuthCard width="450px" showLogo={false}>
          <Flex direction="column" width="100%" gap="1em" margin="1em 0 0 0">
            <h3 style={{ margin: "0px", marginBottom: "1em" }}>Lock This Account</h3>

            <InputField
              label="Enter pin to continue"
              placeholder="Enter pin to continue"
              type="password"
              noFormat={true}
              backgroundColor="#F4F6F9"
              size="lg"
              color={blackLight}
              borderColor="transparent"
              borderRadius="0.75rem"
              borderSize="0px"
              fontSize={`${fontSizes.base}`}
              width="100%"
              value={pin}
              labelMargin="0 0 1em 0!important"
              onChange={(e) => setPin(e.target.value)}
            />
            <Button
              label="Continue"
              onClick={handleSubmit}
              type="submit"
              backgroundColor={primaryColor}
              size="lg"
              color="#fff"
              borderColor="transparent"
              borderRadius="0.75rem"
              borderSize="0px"
              fontSize={`${fontSizes.base}`}
              width="100%"
              margin="1em 0 1em 0"
            />
          </Flex>
        </AuthCard>
      </ModalBox>
    </ModalWrapper>
  );
};

export default ComfirmPinLock;
