/* eslint-disable func-call-spacing */
/* eslint-disable no-debugger */
/* eslint-disable indent */
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Dialog } from "@headlessui/react";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IconContext } from "react-icons";
import { VscSync, VscSyncIgnored } from "react-icons/vsc";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useCurrentShop,
} from "../../app/hooks";
import {
  closePreLockModal,
  lock,
  setNoPermissionModal,
  showLockModal,
} from "../../app/slices/accountLock";
import { setSingleInventory } from "../../app/slices/inventory";
import { setIsEdit } from "../../app/slices/isEdit";
import { getUserPermissions } from "../../app/slices/roles";
import {
  setFilterByDiscountSales,
  setFilterByRefundSales,
  setPaymentFilter,
  setProductFilterList,
  setProductIdFilterList,
  setReceiptNumber,
  setUserFilterList,
  setUserIdFilterList,
} from "../../app/slices/salesFilter";
import {
  setBusinessSettingsProp,
  setTopNavProp,
} from "../../app/slices/settings";
import { toggle } from "../../app/slices/sidebar";
import { toggleSnackbarOpen } from "../../app/slices/snacbar";
import {
  setFeatureCount,
  setSubscriptionPackages,
} from "../../app/slices/subscriptionslice";
import { setBannerData } from "../../app/slices/syncStatus";
import { getCurrentUser } from "../../app/slices/userInfo";
import cancelIcon from "../../assets/cancel.svg";
import LeaveShopIconRed from "../../assets/LeaveShopIconRed.svg";
import companyConfig from "../../config";
import { Flex, Span, Text } from "../../GlobalStyles/CustomizableGlobal.style";
import { Colors, fontSizes } from "../../GlobalStyles/theme";
import { syncTotalTableCount } from "../../helper/comparisons";
import { convertToLocalDateTime } from "../../helper/date";
import analytics from "../../helper/firebase.helper";
import { handleNewPushNotificationMessage } from "../../helper/inventory.helper";
import { subscriptionPackageRestriction } from "../../helper/subscription.helper";
import { PushNotificationPayload } from "../../interfaces/shop.interface";
import {
  IAdditionalFeatures,
  PackageData,
} from "../../interfaces/subscription.interface";
import { IUnlockAccount } from "../../pages/unlockModal/unlockModal";
import {
  CAN_USER_SET_PIN,
  GET_USERS_WITH_PIN,
  UPDATE_USER_TOKEN,
} from "../../schema/auth.schema";
import {
  GET_FEATURE_COUNT,
  GET_SUBSCRIBTION_PACKAGES,
} from "../../schema/subscription.schema";
import { clientLocalSocket } from "../../services/clientLocalSocket";
import { initSocket } from "../../services/clientServerSocket";
import useNetworkStatus from "../../utils/checkInternet.utils";
import {
  PUSH_NOTIFICATION,
  SYNC_STATUS,
  isStaging,
} from "../../utils/constants";
import { askNotificationPermission } from "../../utils/firebase.utils";
import { getInitials } from "../../utils/formatValues";
import { isDesktop } from "../../utils/helper.utils";
import isMobileOrTablet from "../../utils/isMobileOrTablet";
import {
  getItem,
  getItemAsArray,
  setItem,
} from "../../utils/localStorage.utils";
import { useLogout } from "../../utils/logout.util";
import { Button } from "../button/Button";
import { syncStatusProps } from "../dashboard-wrapper/dashboard-wrapper";
import { ModalBox } from "../expenseModal/style";
import ConfirmAction from "../modal/confirmAction";
import EdmartSubModal from "../modal/edmartSubscriptionModal";
import { ModalWrapper } from "../modal/ModalWrapper";
import SyncModal from "../sync-modal/syncModal";
import ComfirmPinLock from "./comfirmLockPin";
import LockAccount from "./lockAccount";
import LogoutModal from "./logoutModal";
import NewSidebar from "./newSidebarItems";
import { SidebarContainer, UserAccronym } from "./newSideStyles";
import { ArrowIcon } from "./SIdebarIcons";
import { Logo, SyncButton, SyncWrapper } from "./style";

interface ISidebar {
  startSync: () => void;
  syncStatus?: syncStatusProps;
}

const Sidebar: FunctionComponent<ISidebar> = ({ startSync }) => {
  const userPermissions = useAppSelector(getUserPermissions);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentShop = useCurrentShop();
  const shopId = currentShop?.shopId;
  const {
    user: userInfo,
    accountLock,
    sidebar: sidebarToggle,
    subscriptions,
  } = useAppSelector((state) => state);

  analytics.setUserId(userInfo?.userId as string);
  analytics.setShopId(shopId as string);
  const currentUser = useAppSelector(getCurrentUser);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [confirmSignout, setConfirmSignout] = useState(false);
  const [syncStatus, setSyncStatus] = useState<syncStatusProps>({
    running: false,
  });
  const [ableToSetPin, setAbleToSetPin] = useState<boolean>(false);
  const [showWarn, setShowWarn] = useState<boolean>(false);
  const [showComfirmPinModal, setShowComfirmPinModal] =
    useState<boolean>(false);
  const [showEdmartSubInfoModal, setShowEdmartSubInfoModal] =
    useState<boolean>(false);
  const mobileOrTablet = isMobileOrTablet();
  const [showSyncModal, setShowSyncModal] = useState<boolean>(false);
  const [showShopDeletedWarning, setShowshopDeletedWarning] =
    useState<boolean>(false);
  const userSubscriptions = subscriptions?.subscriptions[0] || [];
  const subscriptionPackages = subscriptions?.subscriptionPackages || [];
  const featureCount = subscriptions?.featureCount || {};
  const { isOnline } = useNetworkStatus();

  const location = useLocation();

  const syncTableUpdateCount = useAppSelector((state) =>
    syncTotalTableCount(state.shops.syncTableUpdateCount, [
      "UserSubscription",
      "UserSubscriptionAddon",
      "Inventory",
      "Supplies",
      "TrackableItems",
      "Customer",
      "CustomerTransaction",
      "InventoryQuantity",
    ])
  );

  const { data: usersWithPin } = useQuery<{
    getAuthenticatedUsersWithPin: IUnlockAccount[];
  }>(GET_USERS_WITH_PIN, {
    fetchPolicy: "cache-and-network",
    variables: {
      deviceUUID: `${getItem("DEVICE_UUID")}`,
    },
    onCompleted(data) {
      if (isDesktop()) {
        setItem("usersWithPin", data?.getAuthenticatedUsersWithPin);
      }
    },
    onError: () => {
      dispatch(
        toggleSnackbarOpen({
          message:
            "An error occurred while fetching users with pin data. Please try again later.",
          color: "DANGER",
        })
      );
    },
  });

  const { handleLogout } = useLogout({
    userInfo,
    usersWithPin,
    isOnline,
    setShowshopDeletedWarning,
  });

  useEffect(() => {
    initSocket();
  }, []);

  const [updateIsPinEnable] = useMutation<{
    updateIsPinEnabled: boolean;
  }>(UPDATE_USER_TOKEN, {
    onError: () => {
      dispatch(
        toggleSnackbarOpen({
          message: "Failed to update user token",
          color: "DANGER",
        })
      );
    },
  });

  const [refetchFeatureCount] = useLazyQuery<{
    getFeatureCount: { inventoriesCount: number; debtCount: number };
  }>(GET_FEATURE_COUNT, {
    variables: {
      shopId: currentShop?.shopId,
    },
    fetchPolicy: "cache-and-network",
    onCompleted(arrData) {
      dispatch(setFeatureCount(arrData?.getFeatureCount ?? {}));
    },
    onError: (error) => {
      dispatch(
        toggleSnackbarOpen(error?.message || error?.graphQLErrors[0]?.message)
      );
    },
  });

  const [fetchSubscriptionPackage] = useLazyQuery<{
    getSubscriptionPackages: PackageData[];
  }>(GET_SUBSCRIBTION_PACKAGES, {
    fetchPolicy: "no-cache",
    onCompleted(arrData) {
      dispatch(setSubscriptionPackages(arrData?.getSubscriptionPackages ?? []));
    },
    onError: (error) => {
      dispatch(
        toggleSnackbarOpen(error?.message || error?.graphQLErrors[0]?.message)
      );
    },
  });

  useEffect(() => {
    if (currentShop?.shopId) {
      refetchFeatureCount();
      fetchSubscriptionPackage();
    }
  }, [syncTableUpdateCount, currentShop]);

  useEffect(() => {
    if (currentShop) {
      localStorage.setItem(
        "currencyCode",
        (currentShop?.currencyCode as string) || "NGN"
      );
    }
  }, [currentShop]);

  useEffect(() => {
    askNotificationPermission();
  }, []);

  const [getUserPinData] = useLazyQuery<{
    hasSetUserPin: boolean;
  }>(CAN_USER_SET_PIN, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      setAbleToSetPin(data?.hasSetUserPin);
    },
    onError(error) {
      dispatch(
        toggleSnackbarOpen({
          message: error?.message || error?.graphQLErrors[0]?.message,
          color: "DANGER",
        })
      );
    },
  });

  useEffect(() => {
    if (!userInfo?.userId) return;
    getUserPinData();
  }, [userInfo]);

  const handleProfileCardClick = () => {
    setShowLogoutModal(true);
  };

  const handleShiftEnd = async () => {
    const hasComfirmedPins = getItemAsArray("hasConfirmedPins");
    const hasPin = await getUserPinData();
    if (hasPin?.data?.hasSetUserPin) {
      const hasComfirmedPin = hasComfirmedPins?.some(
        (user: { userId: string; hasConfirmedPin: boolean }) =>
          user?.userId === userInfo?.userId
      );

      if (!hasComfirmedPin) {
        setShowComfirmPinModal(true);
        return;
      }
      dispatch(showLockModal());
      dispatch(lock({ lock: true }));
      await updateIsPinEnable({
        variables: {
          isPinEnabled: true,
        },
      });
    } else {
      setShowWarn(true);
    }
  };

  useEffect(() => {
    if (location.pathname === "/logout") {
      handleLogout();
    }
  }, [location]);

  useEffect(() => {
    clientLocalSocket.emit(SYNC_STATUS, { shopId });
  }, [shopId]);

  const handleStatusChange = useCallback(
    (data) => {
      if (Number(data.lastSyncDate > 0)) {
        setItem("lastSyncTime", data.lastSyncDate);
        setItem("lastValidSystemTime", new Date().getTime());
        setItem("timezone", {
          currentTimezoneOffset: new Date().getTimezoneOffset(),
          lastSyncTimezoneOffset: new Date(
            data.lastSyncDate
          ).getTimezoneOffset(),
        });
      }
      setSyncStatus(data);
      const { more, ...rest } = data;
      dispatch(setBannerData(rest));
    },
    [setSyncStatus]
  );

  const handlePushNotification = useCallback(
    (res: PushNotificationPayload) => {
      handleNewPushNotificationMessage(res, shopId as string, navigate);
    },
    [handleNewPushNotificationMessage]
  );

  useEffect(() => {
    clientLocalSocket.on(SYNC_STATUS, handleStatusChange);
    clientLocalSocket.on(PUSH_NOTIFICATION, handlePushNotification);

    return () => {
      clientLocalSocket.removeListener(SYNC_STATUS, handleStatusChange);
      clientLocalSocket.removeListener(
        PUSH_NOTIFICATION,
        handlePushNotification
      );
    };
  }, [shopId, handleStatusChange, handlePushNotification]);

  const handleClearFilter = () => {
    dispatch(setPaymentFilter([]));
    dispatch(setProductFilterList([]));
    dispatch(setProductIdFilterList([]));
    dispatch(setUserFilterList([]));
    dispatch(setUserIdFilterList([]));
    dispatch(setReceiptNumber(""));
    dispatch(setFilterByDiscountSales(false));
    dispatch(setFilterByRefundSales(false));
  };

  const handleSubscriptionCheck = (check: IAdditionalFeatures["check"]) => {
    const isProgress = subscriptionPackageRestriction(
      userSubscriptions,
      subscriptionPackages,
      featureCount,
      dispatch,
      check
    );
    return isProgress;
  };

  const handleClick = (path: string, shouldAccess: boolean) => {
    if (path.includes("/invoices")) {
      if (!handleSubscriptionCheck("Invoice")) {
        return;
      }
    }

    if (path.includes("/suppliers")) {
      if (!handleSubscriptionCheck("Supplies")) {
        return;
      }
    }

    if (path.includes("/product/add")) {
      if (!handleSubscriptionCheck("inventory")) {
        return;
      }
    }

    if (path.includes("/product/transfer")) {
      if (!handleSubscriptionCheck("ProductTransfer")) {
        return;
      }
    }

    if (path === "#" || !shouldAccess) {
      !shouldAccess &&
        dispatch(
          toggleSnackbarOpen({
            message: "Access denied, Contact your manager",
            color: "INFO",
          })
        );
      dispatch(setNoPermissionModal(true));
      return;
    }
    if (path.includes("?route=printer")) {
      dispatch(setTopNavProp("Business Settings"));
      dispatch(setBusinessSettingsProp("Printer Settings"));
    }
    navigate(`${path}`);
    handleClearFilter();
  };

  // eslint-disable-next-line no-unneeded-ternary
  const closeSidebar = () => dispatch(toggle());
  const lastSyncedTime: number = Number(
    syncStatus && Number(syncStatus.lastSyncDate) > 0
      ? syncStatus.lastSyncDate
      : getItem("lastSyncTime")
  );

  const { running, totalRecordsToPush, error, percentage } = syncStatus;

  const SidebarContainerWrapper = useMemo(() => {
    const getSyncStatusMessage = () => {
      if (running) {
        return totalRecordsToPush
          ? `${totalRecordsToPush} Uploading/Syncing Records...`
          : "Getting Updates...";
      }

      if (Number(error?.length) > 0 && !isOnline) {
        return "No Internet Connection";
      }

      if (error) {
        return error.length > 20 ? `${error.substring(0, 20)}...` : error;
      }

      return lastSyncedTime
        ? `Last Updated: ${convertToLocalDateTime(
            new Date(lastSyncedTime),
            "D MMM, YY. h:mmA"
          )}`
        : "Last Updated: --";
    };

    return (
      <SidebarContainer
        show={sidebarToggle}
        onClick={() => {
          dispatch(setSingleInventory({}));
          dispatch(setIsEdit(false));
        }}
      >
        {isStaging && (
          <Flex
            color={Colors.red}
            style={{ fontWeight: "600" }}
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            Staging
          </Flex>
        )}

        <Flex
          justifyContent="center"
          mdResponsive="justify-content: space-between; width: 80%; align-items: center; margin-inline: auto;"
        >
          <Logo>
            <img src={companyConfig.appLogo} alt="" />
          </Logo>
          {mobileOrTablet && (
            <Flex width="20px" height="20px" onClick={closeSidebar}>
              <img src={cancelIcon} alt="" />
            </Flex>
          )}
        </Flex>

        {isDesktop() && (
          <SyncWrapper>
            <SyncButton onClick={() => startSync()}>
              <IconContext.Provider
                value={{ color: Colors.secondaryColor, size: "1em" }}
              >
                <>{running ? <VscSyncIgnored /> : <VscSync />}</>
              </IconContext.Provider>
            </SyncButton>

            <div
              style={{
                color: Colors.secondaryColor,
                fontSize: fontSizes.small,
              }}
            >
              {!running && totalRecordsToPush ? (
                <>
                  <span style={{ fontWeight: 700 }}>
                    {totalRecordsToPush === 0
                      ? ""
                      : syncStatus.totalRecordsToPush}
                  </span>{" "}
                  record(s) yet to be uploaded to server
                </>
              ) : (
                ""
              )}
            </div>

            <Flex
              cursor="pointer"
              alignItems="center"
              justifyContent="center"
              width="auto"
              direction={
                !running && Number(error?.length) > 10 ? "column" : "row"
              }
              onClick={() => setShowSyncModal(true)}
            >
              {running && (
                <Flex
                  margin="0 0 0 0.5rem"
                  fontSize={fontSizes.small}
                  justifyContent="center"
                  alignItems="center"
                  gap="0.5rem"
                >
                  <Text color="white">{`${Math.round(percentage ?? 0)}%`}</Text>
                  <Text color="white">{getSyncStatusMessage()}</Text>
                </Flex>
              )}
              {!running && (
                <Flex
                  flexWrap="wrap"
                  justifyContent="flex-start"
                  margin="0 0 0 0.5rem"
                >
                  <Text color="#fff">{getSyncStatusMessage()}</Text>
                </Flex>
              )}
            </Flex>
          </SyncWrapper>
        )}

        <div className="nav-wrapper">
          <ul className="main-nav">
            <NewSidebar
              handleClick={handleClick}
              userPermissions={userPermissions}
            />
          </ul>
        </div>

        <div>
          <Flex
            bg="#2E2261"
            borderRadius=".75rem"
            padding=".625rem"
            height="3.4375rem"
            minHeight="3.4375rem"
            justifyContent="space-between"
            alignItems="center"
            margin="1rem auto"
            className="logout"
            width="95%"
            cursor="pointer"
            onClick={handleProfileCardClick}
          >
            <Flex
              height="100%"
              alignItems="flex-start"
              justifyContent="space-between"
              direction="column"
              gap="0.5rem"
              bg="transparent"
            >
              <Flex
                height="100%"
                alignItems="center"
                justifyContent="flex-start"
                gap="0.5rem"
                bg="transparent"
              >
                <UserAccronym>
                  {getInitials(currentUser?.fullName)}
                </UserAccronym>
                <Flex
                  height="fit-content"
                  width="70%"
                  direction="column"
                  cursor="pointer"
                  bg="transparent"
                  data-tip={currentUser?.fullName}
                  data-for="profileTooltip"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  hover
                >
                  <Span color={Colors.white} fontSize={fontSizes.base}>
                    {currentUser.fullName}
                  </Span>
                  <Span color={Colors.white} fontSize={fontSizes.small}>
                    View Profile
                  </Span>
                </Flex>
                <Flex height="100%" width="8%" alignItems="flex-end">
                  <ArrowIcon />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            className="end-shift"
            onClick={handleShiftEnd}
            width="fit-content"
            style={{ marginInlineStart: "1rem" }}
            alignItems="center"
            gap="0.4rem"
          >
            <img src={LeaveShopIconRed} alt="Lock shop icon" />
            <p
              style={{ fontWeight: "500", color: "#F65151", cursor: "pointer" }}
            >
              Lock/ End Shift
            </p>
          </Flex>
        </div>
      </SidebarContainer>
    );
  }, [
    currentUser,
    syncStatus,
    syncStatus.running,
    startSync,
    userSubscriptions,
    subscriptions,
    mobileOrTablet,
    sidebarToggle,
    handleClick,
    userPermissions,
  ]);

  useEffect(() => {
    if (error) {
      setShowshopDeletedWarning(
        error
          ?.toLowerCase()
          .includes("you no longer have access to this shop") || false
      );
    }
  }, [error]);

  useEffect(() => {
    if (accountLock?.isLockModalActive) {
      navigate("/unlock-account");
    }
  }, [accountLock?.isLockModalActive]);

  return (
    <>
      {!mobileOrTablet && SidebarContainerWrapper}

      {showLogoutModal && (
        <LogoutModal
          setConfirmSignout={setConfirmSignout}
          setShowLogoutModal={setShowLogoutModal}
        />
      )}

      {confirmSignout && (
        <ConfirmAction
          action="Logout"
          actionText="Are you sure you want to logout?"
          setConfirmSignout={setConfirmSignout}
          doAction={handleLogout}
        />
      )}

      {showWarn && !ableToSetPin ? (
        <LockAccount
          closePreLockModal={closePreLockModal}
          setShowWarn={setShowWarn}
        />
      ) : null}

      {showComfirmPinModal && (
        <ComfirmPinLock setShowComfirmPinModal={setShowComfirmPinModal} />
      )}

      {showEdmartSubInfoModal && (
        <EdmartSubModal setShowModal={() => setShowEdmartSubInfoModal(false)} />
      )}

      {showSyncModal && (
        <ModalWrapper onClose={() => setShowSyncModal(false)}>
          <SyncModal
            setShowSyncModal={setShowSyncModal}
            data={syncStatus}
            lastSyncedTime={lastSyncedTime}
            startSync={startSync}
          />
        </ModalWrapper>
      )}

      {showShopDeletedWarning && (
        <ModalWrapper>
          <ModalBox>
            <Flex
              height="fit-content"
              padding="3.5rem 0 0 0"
              direction="column"
              justifyContent="center"
            >
              <Span color={Colors.grey} textAlign="center" margin="0.625rem 0">
                <h3>You don’t have access to this shop anymore!</h3>
                Navigate to select shop page to enter another shop or logout.
              </Span>
            </Flex>
            <Flex
              justifyContent="space-between"
              margin="1.25rem 0 0 0"
              alignItems="center"
            >
              <Button
                margin="0.9375rem 0"
                label="Select Another Shop"
                width="45%"
                height="2.5rem"
                borderRadius="0.75rem"
                color={Colors.white}
                backgroundColor={Colors.primaryColor}
                onClick={() => navigate("/select-shop")}
              />
              <Button
                margin="0.9375rem 0"
                label="Logout"
                width="45%"
                height="2.5rem"
                borderRadius="0.75rem"
                color={Colors.white}
                backgroundColor={Colors.secondaryColor}
                onClick={handleLogout}
              />
            </Flex>
          </ModalBox>
        </ModalWrapper>
      )}

      {mobileOrTablet && (
        <Dialog open={sidebarToggle} onClose={closeSidebar}>
          <div style={{ position: "fixed", inset: "0", zIndex: "999" }}>
            <Dialog.Panel
              style={{ zIndex: "9999", position: "fixed", insetBlock: 0 }}
            >
              {SidebarContainerWrapper}
            </Dialog.Panel>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default Sidebar;
